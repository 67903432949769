@import '~@amzn/awsui-design-tokens/polaris.scss';

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.whole-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.website-navigation-top {
  padding: 0 1.5em;
  color: $color-foreground-control-default;
  display: flex;
  align-items: center;
  flex: 0 0 50px;
}
.website-navigation-top {
  background-color: #001529;
}
.website-navigation-top-logo {
  flex: 0 0 auto;
  font-weight: 400;
  font-size: 2em;

  .logo-link {
    line-height: 0.8;
    color: #fff;
    font-size: 3rem;
    display: flex;
    align-items: center;
  }
  .logo {
    height: 3rem;
    padding-right: 0.5em;
  }
  .logo-link:focus,
  .logo-link:hover {
    text-decoration: none;
  }
}

.website-content {
  flex: 1 1 0;
  overflow-y: auto;
  max-width: 100%;
  background-color: $color-background-dropdown-item-hover;
}
.profile-picture {
  flex: 0 0 auto;
  margin-left: 1em;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.awsui .icon-small .awsui-icon {
  height: auto;
  width: auto;
  svg {
    height: 1rem;
    width: 1rem;
  }
}
.monospace {
  font-family: monospace;
}
.link {
  color: $color-text-link-default;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
.link-bold {
  font-weight: 800;
}

.error-box {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1em;
  max-width: 60em;
  z-index: 100;
}
.error-box > awsui-flash {
  margin: 0.5em 0;
}

// make the current page indicator more visible
.awsui .website-content .awsui-table-pagination-current-page {
  background-color: #ddd;
}
.awsui.awsui-polaris-dark-mode
  .website-content
  .awsui-table-pagination-current-page {
  background-color: $color-background-dropdown-item-hover;
}

// limit table cell width to prevent bad data from overly expanding a table column
td {
  max-width: 500px;
}
